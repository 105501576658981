import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import setAxios from './setAxios'
import Cookie from 'js-cookie'
setAxios()
// 引入 iconfont
import './assets/font/iconfont.css';

import ElementUI from 'element-ui' 
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/rest.scss'
Vue.use(ElementUI) //使用elementUI

// 引入markDown 编辑器
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

axios.defaults.baseURL = 'http://101.43.228.111:3000/';
Vue.prototype.$axios = axios

router.beforeEach((to,from,next)=>{    //拦截
  store.commit('setToken',Cookie.get('token'))   //从Cookie里面获取token,然后重新设置一下(setToken)
 console.log(1);
  if(store.state.token){   //判断vuex里面是否有token
    store.commit('changIsSignIn',1) // 有token的时候设置一下状态
    console.log(2);
  }
  if(to.meta.requireAuth){    //守卫拦截
    console.log(3);
    if(store.state.token){   //判断vuex里面是否有token
      next()
      console.log(4);
    }else{
      console.log(123);
      next({path:'./login'})
    }
  }else{
    next()
  }
 
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
