import axios from 'axios'
import store from './store/index'
axios.defaults.baseURL = 'http://101.43.228.111:3000/';

export default function setAxios(){
    //请求拦截
    axios.interceptors.request.use(
        config=>{
            // console.log(config)
            // if(store.state.token){
            //     config.headers['Authorization']= `Bearer ${store.state.token}`  //添加token到响应头里面
            // }
            return config
        }
    )
}